.AppHeader {
    /* background-color: #282c34; */
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.AppLogo {
    height: 10vmin;
    pointer-events: none;
    margin: 1vmin
}

@keyframes AppLogoSpin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .AppLogo {
        animation: AppLogoSpin infinite 20s linear;
    }
}

.RecipeApp {
    text-align: center;
}

.display-linebreak {
    white-space: pre-wrap;
}

/* Inserting a collapsed row between two flex items will make 
   * the flex item that comes after it break to a new row.
   * Ref: https://tobiasahlin.com/blog/flexbox-break-to-new-row/
   */

.break {
    flex-basis: 100%;
    height: 0.1em;
}

.new-recipe-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    width: 8rem;
}