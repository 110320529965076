/* .MoveButton {
    display: inline-block;
    text-align:center;
    border-radius:20px;
    animation: border-flicker 2s linear infinite;
    -webkit-box-shadow: 0px 0px 28px 4px rgba(16,134,232,0.73);
    -moz-box-shadow: 0px 0px 28px 4px rgba(16,134,232,0.73);
    box-shadow: 0px 0px 28px 4px rgba(16,134,232,0.73);
}

.MoveButton button {
    opacity: 1;
}

@keyframes border-flicker {    
    0% {
        opacity:1;

    }
    70% {
        opacity:0.4;

    }
    100% {
        opacity:1;

    }
} */

.MoveButton {
    display: inline-block;
    box-shadow: none;
    border-radius: 25px;
    transition: box-shadow 0.3s ease-in-out;
}
.MoveButton-highlight {
    box-shadow: 0 0 60px -5px rgba(255, 215, 93, 0.7);
}
/* .MoveButton:hover {
    box-shadow: 0 20px 160px -25px rgba(255, 255, 255, 0.7);
} */