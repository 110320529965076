.AppHeader {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppLogo {
  height: 10vmin;
  pointer-events: none;
  margin: 1vmin
}

@keyframes AppLogoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: AppLogoSpin infinite 20s linear;
  }
}

.DrawerApp {
  text-align: center;
  background-color: darkslategrey;
  height: 100vh;                  /* Takes the full screen */
  color: white;
}

.Drawer {
  text-shadow: .05em 0 0 rgb(119, 119, 119);
}

.Drawer .PreviewImage {
  max-width: 80vw;
  max-height: 33vh;
}

/* 也许可以实现的烟雾效果：https://juejin.cn/post/7046573911957307405 */