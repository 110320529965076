.AppHeader {
    /* background-color: #282c34; */
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.AppLogo {
    height: 10vmin;
    pointer-events: none;
    margin: 1vmin
}

@keyframes AppLogoSpin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .AppLogo {
        animation: AppLogoSpin infinite 20s linear;
    }
}

.PhotoWallApp {
    text-align: center;
}

.Photo {
    transition: all .5s ease-in-out;
}

.Photo:hover {
    transform: scale(1.06);
}